import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ResourceMetadataTemplate, PlaylistProperties, Resource, KioskProperties, defaultKioskUrlType, KioskUrlType } from '@domain/resources';
import { Campaign } from '@config/campaign';


export class ResourcesCreator {

    constructor(public fb: UntypedFormBuilder) { }

    public createAssignNameForm({ name }: { name: string }): UntypedFormGroup {
        return this.fb.group({ name: [name, [Validators.required]] });
    }

    public createExpireForm(): UntypedFormGroup {
        return this.fb.group({ expireAfter: [null, [Validators.required]] });
    }

    public createResourceMetadataForm(metadata: ResourceMetadataTemplate): UntypedFormGroup {

        const playlistProperties = metadata.playlistProperties ||
            new PlaylistProperties({ color: Campaign.playlistDefaultSettings.color, duration: Campaign.playlistDefaultSettings.duration });

        return this.fb.group({
            updateTags: [metadata.updateTags],
            tags: [metadata.tags || []],
            updatePlaylistProperties: metadata.updatePlaylistProperties,
            playlistProperties: this.fb.group({
                color: [playlistProperties.color],
                duration: [playlistProperties.duration]
            }),
            updateExpireAfter: [metadata.updateExpireAfter],
            expireAfter: [metadata.expireAfter]
        });
    }

    public createWebPageForm(params: { name: string; content: { url: string } }): UntypedFormGroup {
        return this.fb.group({
            name: [params.name, [Validators.required]],
            content: this.fb.group({
                url: [params.content.url, [Validators.required]],
            }),
        });
    }

    public createAudioStreamForm(params: { name: string; subtype: string; content: { url: string } }): UntypedFormGroup {
        return this.fb.group({
            name: [params.name, [Validators.required]],
            subtype: [params.subtype, [Validators.required]],
            content: this.fb.group({
                url: [params.content.url, [Validators.required]],
            }),
        });
    }

    public createTickerForm(resource: Resource): UntypedFormGroup {
        return this.fb.group({
            name: [resource.name, [Validators.required]],
            type: [resource.type, [Validators.required]],
            subtype: [resource.subtype, [Validators.required]],
            content: this.fb.group({
                text: [resource.content?.text, [Validators.required]],
                disabled: [resource.content?.disabled || false],
                continuous: [resource.content?.continuous || false],
                properties: this.fb.group({
                    backgroundColor: [resource.content?.properties?.backgroundColor, [Validators.required]],
                    opacity: [resource.content?.properties?.opacity, [Validators.required]],
                    color: [resource.content?.properties?.color, [Validators.required]],
                    size: [resource.content?.properties?.size, [Validators.required]],
                    speed: [resource.content?.properties?.speed, [Validators.required]],
                }),
            })
        });
    }

    public createKioskForm(resource: Resource): UntypedFormGroup {
        const urlType = resource.content?.urlType || defaultKioskUrlType;

        return this.fb.group({
            name: [resource.name, [Validators.required]],
            type: [resource.type, [Validators.required]],
            content: this.fb.group({
                url: [resource.content?.url, urlType === KioskUrlType.external ? [Validators.required] : []],
                urlType: [urlType, [Validators.required]],
                resourceId: [resource.content?.resourceId || null, urlType === KioskUrlType.internal ? [Validators.required] : []],
                properties: this.createKioskPropertiesGroup(resource.content?.properties),
            })
        });
    }

    private createKioskPropertiesGroup(properties: KioskProperties): UntypedFormGroup {
        return this.fb.group({
            backgroundColor: [properties?.backgroundColor, [Validators.required]],
            color: [properties?.color, [Validators.required]],
            size: [properties?.size, [Validators.required]],
            position: [properties?.position, [Validators.required]],
            navigator: [properties?.navigator],
        });
    }
}
